import { graphql } from 'gatsby'
import React from 'react'

import Breadcrumb from '../../components/breadcrumb'
import Heading from '../../components/heading'
import Layout from '../../components/layout'
import Link from '../../components/link'
import List from '../../components/list'
import ListItem from '../../components/list-item'
import SEO from '../../components/seo'


export const query = graphql`
  query BrandsSitemapPageQuery($genderId: PostGraphile_Gender!) {
    ...SiteInfo
    results: postgres {
      brands: allBrandsList(
        filter: { genders: { contains: [ $genderId ] }},
        orderBy: NAME_ASC,
      ) {
        ...Brand
      }
    }
  }
`

export default ({ data }) => {
  const { links, meta } = data.site.siteMetadata
  const { brands } = data.results
  const { entity } = meta

  const page = `${entity.singular} Brands Sitemap`
  const title = `${page} - Sitemaps`
  const keywords = title.split(/\W+/)
  const trail = [
    {
      name: 'Sitemap',
      slug: links.sitemap.home,
    },
    {
      name: 'Brands',
      slug: links.sitemap.brands,
    }
  ]
  const schema = {
    breadcrumb: trail,
    description: title,
    keywords,
    name: title,
    slug: links.sitemap.brands,
  }

  return (
    <Layout>
      <SEO keywords={keywords} schema={schema} title={title} />
      <Breadcrumb trail={trail} />

      <Heading>{page}</Heading>

      <List>
        <ListItem>
          <Link href={links.brands}>All Brands</Link>
        </ListItem>
        {brands.map(brand => (
          <ListItem key={brand.id}>
            <Link href={brand.slug}>{brand.name}</Link>
          </ListItem>
        ))}
      </List>

      <Breadcrumb trail={trail} />
    </Layout>
  )
}
